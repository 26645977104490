import { PageHandler } from 'components/ContentHandlers'
import { generateWebPageProps } from 'utils/next'
import { setPageCacheControlHeaders } from 'utils/cache'
import FEATURE_TOGGLES from 'constants/featureToggles'

export async function getServerSideProps(context) {
  setPageCacheControlHeaders(context.res)
  const serverSideProps = await generateWebPageProps(context)
  const accelerationLoanEnabled =
    serverSideProps?.props?.featureToggleObject?.[FEATURE_TOGGLES.ACX_WEB_ENABLE_ACCELERATION_LOAN]
  if (!accelerationLoanEnabled) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    }
  }
  return serverSideProps
}

export default function AccelerationLoan({ content, isMobile }) {
  return <PageHandler pageContent={content} isMobileUA={isMobile} />
}
